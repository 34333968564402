@use "@/styles/variables" as *;

$diameter: 75px !default;
$color: $color-white !default;
$background: $color-black !default;
$font-size: $font-size-xs !default;

.root {
  position: relative;
  cursor: none;
}

.cursor {
  position: absolute;
  transform: translate(-#{$diameter / 2}, -#{$diameter / 2});
  width: $diameter;
  height: $diameter;
  border-radius: 50%;
  background: $background;
  color: $color;
  font-size: $font-size;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  opacity: 0;
  transition: opacity 0.2s ease-in-out, transform 0.15s ease-out;
  pointer-events: none;
}

.visible {
  opacity: 1;
}
