@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.contentContainer {
  width: min(100%, $wrapper-max-width);
  margin-block: $space-md;
  margin-inline: auto;

  @include md-min {
    padding-inline: $space-xxs;
  }
}

.slideContent {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  overflow: hidden;

  @include md-min {
    flex-direction: row;
  }
}

/* Whether template is reversed */
.flipped {
  @include md-min {
      flex-direction: row-reverse;
  }
}

.slideText,
.slideImage {
  width: 100%;

  @include md-min {
    width: 100%;
  }
}

.slideText {
  display: grid;
  place-content: center;

  @include md-min {
    text-align: left;
    margin-right: $space-md;
  }
}

.sliderContainer {
  margin-inline: auto;
}

.paginationButton {
  @include pagination-button;
}

.paginationButtonActive {
    @include pagination-button-active;
}
