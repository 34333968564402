@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

/* Navigation arrows */
$navigation-width: auto !default;
$navigation-height: auto !default;
$navigation-border: 1px solid $carousel-navigation !default;
$navigation-color: $carousel-navigation !default;
$navigation-background: $carousel-navigation-background !default;
$navigation-transition: all 1s !default;
$navigation-hover-border: 1px solid $color-black !default;
$navigation-hover-color: $color-black !default;
$navigation-hover-background: inherit !default;

/* Pagination indicators */
$indicator-width: 8px !default;
$indicator-height: 8px !default;
$indicator-outline: 2px solid $carousel-navigation !default;
$indicator-outline-offset: 1px !default;
$indicator-background-color: $color-light !default;
$indicator-margin: $space-xxs !default;
$indicator-active-outline: 2px solid $carousel-navigation !default;
$indicator-active-background-color: $carousel-navigation !default;

.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

:is(body) {
  .navigationWrapper {
    height: 100%;
  }

  .navigation {
    @include square;

    width: $navigation-width;
    height: $navigation-height;
    border-radius: 50%;
    border: $navigation-border;
    color: $navigation-color;
    background: $navigation-background;
    transition: $navigation-transition;

    svg {
      transform: scale(0.7);
      width: 70px;
    }

    @media (hover: hover) {
      &:hover {
        border: $navigation-hover-border;
        color: $navigation-hover-color;
        background: $navigation-hover-background;
      }
    }
  }

  .pagination {
    margin-bottom: $space-sm;
  }

  .circle {
    width: $indicator-width;
    height: $indicator-height;
    outline: $indicator-outline;
    outline-offset: $indicator-outline-offset;
    background-color: $indicator-background-color;
    margin: $indicator-margin;
  }

  .activeCircle {
    outline: $indicator-active-outline;
    background-color: $indicator-active-background-color;
  }
}
