@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

@forward "./styles.base.module.scss" with (
  $navigation-width: 25px,
  $navigation-height: 25px,
  $navigation-border: none,
  $navigation-color: $color-light,
  $navigation-background: $color-black,
  $navigation-transition: all 0.15s,
  $navigation-hover-border: none,
  $navigation-hover-color: $color-black,
  $navigation-hover-background: $color-light,
  $indicator-outline: 1px solid $color-secondary-500,
  $indicator-outline-offset: 0,
  $indicator-background-color: $color-secondary-500,
  $indicator-margin: $space-xxxs,
  $indicator-active-outline: 1px solid $color-black,
  $indicator-active-background-color: $color-black
);

.contentContainer {
  margin: 0 auto;
  max-width: $page-content-lg-max-width;
}

.title {
  position: relative;
  margin: $space-sm 0 0;
  padding: 0 $space-lg $space-sm;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    display: block;
    left: -50%;
    right: -50%;
    height: 1px;
    background: $color-black;
    margin: 0;
  }
}

:is(body) {
  .wrapper {
    background: transparent;
  }

  .navigationWrapper {
    &::after {
      content: "";
      position: absolute;
      bottom: -1px;
      display: block;
      left: -50%;
      right: -50%;
      height: 1px;
      background: $color-black;
      margin: 0;
    }
  }

  .sliderContainer {
    > div > div {
      border-right: 1px solid $color-black;

      &:last-child {
        border-right: none;
      }

      @include sm-max {
        border-right: none;
      }
    }
  }

  .pagination {
    margin-bottom: $space-sm;
    width: 100%;
  }

  .circle {
    width: 10px;
    height: 10px;
    outline: none;
    outline-offset: 0;
    background-color: $color-secondary-500;
    margin: $space-xxxs;
  }

  .activeCircle {
    background-color: $color-black;
  }
}
