@use "@/styles/variables" as *;
@use "@/styles/helpers/overlayStyles" as *;
@use "@/styles/utils" as *;

.container {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;

  h3 {
    font-size: $font-size-xxxxl;
    margin-bottom: $space-lg;
    transition: transform 0.5s;
  }

  :hover {
    h3 {
      position: relative;
      transform: rotate(8deg);
    }
  }
}

.textSection {
  width: 50%;
}

.block {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.imageSection {
  width: 50%;
  max-height: 100%;
  right: 0;
}

.link {
  width: 100%;
  text-decoration: none;
}

.flip {
  order: -1;
}
