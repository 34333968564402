@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.container {
  position: relative;
  background: $color-secondary-400;

  @include xs-max {
    height: 100vh;
  }
}

.fullHeightMobile {
  @include xs-max {
    height: 100vh;

    // Ensure images remain fully in view
    img {
      object-fit: contain;

      // Allow for space for iOS address bar
      object-position: center calc(100% - 80px);
    }
  }
}

.contentWrap {
  padding: $space-md;
  position: absolute;
  top: 0;
  left: 0;
}

.heading {
  font-weight: $font-weight-base;
  font-size: $font-size-lg;
  margin: 0;
}

.copy {
  font-weight: $font-weight-light;
  font-size: $font-size-sm;
  letter-spacing: $letter-spacing-small-text;
  margin-bottom: $space-xxs;

  p {
    margin: 0;
  }
}

.link {
  display: inline-block;
}

.button {
  min-width: 100px;
}
