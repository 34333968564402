@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

@forward "./styles.base.module.scss" with (
  $navigation-width: 25px,
  $navigation-height: 25px,
  $navigation-border: none,
  $navigation-color: $color-light,
  $navigation-background: $color-black,
  $navigation-transition: all 0.15s,
  $navigation-hover-border: none,
  $navigation-hover-color: $color-black,
  $navigation-hover-background: $color-light,
  $indicator-outline: 1px solid $color-secondary-500,
  $indicator-outline-offset: 0,
  $indicator-background-color: $color-secondary-500,
  $indicator-margin: $space-xxxs,
  $indicator-active-outline: 1px solid $color-black,
  $indicator-active-background-color: $color-black
);

:is(body) {
  .navigationWrapper {
    border: 22px solid $color-black;
    border-top-width: 32px;
    border-bottom-width: 32px;
    box-sizing: border-box;

    @include sm-max {
      border: none;
    }
  }


  .navigation {
    // This property is causing issues in Safari
    // when nested in a container that also uses aspect-ratio (i.e. it squishes elements).
    aspect-ratio: unset;

    &[data-direction="left"] {
      left: 8px;
    }

    &[data-direction="right"] {
      right: 8px;
    }
  }
}
