@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.wrapper {
  height: 100vh;
  transform: scale(0.9);
  transition: transform 0.4s cubic-bezier(0.22, 0.01, 0.01, 0.99);
}

.wrapperInView {
  transform: scale(1);
}

.swiper {
  width: 100%;
}

// .scrollContainer {
//   height: 300vh;
//   position: relative;
// }
