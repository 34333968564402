@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.image {
  width: 100%;
}

.container {
  position: relative;
  overflow: hidden;
}

.textOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: $space-lg;
  z-index: 1;
  line-height: 1;
  color: $color-secondary-900;
  pointer-events: none;
  transition: color 0.3s ease-in-out;
}

.title {
  font-size: $font-size-base;
  font-weight: $font-weight-medium;
}

.body {
  font-size: $font-size-base;
  font-weight: $font-weight-light;

  p {
    margin: 0;
  }
}

.hoverWrapper {
  .image {
    display: none;
  }

  .image:last-child {
    display: flex;
  }

  @include desktop {
    .image:last-child {
      display: none;
    }

    .image:first-child {
      display: flex;
    }

    &:hover {
      .image:first-child {
        display: none;
      }

      .image:last-child {
        display: flex;
      }
    }
  }
}

.enhancedHoverImage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  scale: 1.03;
  transition: opacity 0.3s ease-in-out, scale 0.3s ease-in-out;
}

.enhancedHoverWrapper {
  position: relative;
  overflow: hidden;

  &:hover {
    .enhancedHoverImage {
      opacity: 1;
      scale: 1;
    }

    .textOverlay {
      color: $color-white;
    }
  }
}
